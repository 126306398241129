import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';

// componentes de página
import Main from './pages/Main';
import Login from './pages/Login';
import Flow from './pages/Flow';
import NotFound from './pages/NotFound';
import Calendar from './pages/Calendar';
import AuthContext from './context/AuthContext';

const Routing = () => {
  return (
    <Routes>
      <Route element={ <Login /> } path="/login" exact />
      <Route element={ <NotFound /> } path="*" exact />
      <Route path="/" exact element={
        <ProtectedRoutes>
          <Main />
        </ProtectedRoutes>
      } />
      <Route path="/calendar" exact element={
        <ProtectedRoutes>
          <Calendar />
        </ProtectedRoutes>
      } />
      <Route path="/maintenanceflow" exact element={
        <ProtectedRoutes>
          <Flow />
        </ProtectedRoutes>
      } />
    </Routes>
  )
}

export default Routing;