import React from 'react';
import './style.css';
import logoDefault from './logo-default.png';
import logoPurple from './logo-roxo.png';
import logoWhite from './logo-branco.png';
import logoWhiteWebp from './logo-branco.webp';

const LogoDefault = ({ className }) => {
  return (
    <img src={ logoDefault } className={ className } />
  );
}

const WhiteLogo = ({ className }) => {
  return (
    <img src={ logoWhiteWebp } className={ className } />
  );
}

const PurpleLogo = ({ className }) => {
  return (
    <img src={ logoPurple } className={ className } />
  );
}

export {
  LogoDefault, WhiteLogo, PurpleLogo
}