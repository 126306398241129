import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { 
    BsArrowLeft, 
    BsFilePerson 
} from 'react-icons/bs';
import { 
    BiCalendar, 
    BiPlay, 
    BiBuildings, 
    BiTrash, 
    BiData, 
    BiCommentDetail, 
    BiPen, 
    BiPencil, 
    BiTrashAlt, 
    BiEdit, 
    BiExclude, 
    BiRun, 
    BiFace, 
    BiFile, 
    BiBarChart, 
    BiListOl, 
    BiListUl, 
    BiListCheck,
    BiSolidFilePdf,
    BiClipboard,
    BiFolderOpen,
    BiDetail,
    BiFoodMenu,
    BiCommentCheck,
    BiImage,
    BiPhone,
    BiSolidMap,
    BiTask,
    BiSolidUserAccount,
    BiHardHat,
    BiBlock,
} from 'react-icons/bi';
import { 
    createCalendar, 
    getAllByIdTecnico, 
    deleteCalendar, 
    updateCalendar 
} from '../services/calendar';
import AuthContext from '../context/AuthContext';
import MainContext from '../context/MainContext';
import ModalContext from '../context/ModalContext';
import { Toast } from '../utils/toast';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '../utils/format';
import { getCurrentWeek } from '../utils/dates';
import api from './../services/api'
import Filter from './Calendar/Filter';
import Item from './Calendar/Item';

export default function CalendarMenu() {
    const date = new Date();
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    
    const navigate = useNavigate();

    const { 
        user 
    } = useContext(AuthContext);
    
    const { 
        isListCalendar, setIsListCalendar, 
        calendarArray, setCalendarArray,
        workOrderArray, 
        selectedFlow, setSelectedFlow,
        selectedEquipament, setSelectedEquipament,
        isCalendar, setIsCalendar,
        navigateTo, setNavigateTo,

        title, setTitle,
        description, setDescription,
        idCalendar, setIdCalendar,
        isUpdate, setIsUpdate,
        viewDocs, setViewDocs,
        docs, setDocs,
        viewText, setViewText,
        text, setText,
        calendar, setCalendar,
        viewFlows, setViewFlows,
        flows, setFlows,
        types, setTypes,
        viewReports, setViewReports,
        reports, setReports,
        initDate, setInitDate,
        finishDate, setFinishDate,
        initDateFilter, setInitDateFilter,
        finishDateFilter, setFinishDateFilter,
        isFilter, setIsFilter,
        viewTasks, setViewTasks,
    } = useContext(MainContext);
    
    const { 
        setLoadingModalOpen, 
        flowFinishedModalOpen, setFlowFinishedModalOpen, 
    } = useContext(ModalContext);

    const handleClickAlterUpdate = async function (e, idCalendar, titulo, inicio, fim, descricao) {
        e.preventDefault();

        setIsListCalendar(false);

        setIdCalendar(idCalendar)
        setTitle(titulo);
        setInitDate(inicio.substring(0, 16));
        setFinishDate(fim.substring(0, 16));
        setDescription(descricao);

        setIsUpdate(true);
    }

    const handleClickUpdate = async (e, idCalendar) => {
        e.preventDefault();
        setLoadingModalOpen(true);

        try {
            const parameters = {
                titulo: title,
                inicio: initDate,
                fim: finishDate,
                descricao: description,
            }

            const response = await updateCalendar(parameters, idCalendar);

            if (response.status !== 200) {
                throw new Error('Ocorreu um erro ao tentar incluir um agendamento');
            }

            Toast.fire({
                icon: 'success',
                title: 'Agendamento atualizado'
            })

            const res = await getAllByIdTecnico(user.id, initDateFilter, finishDateFilter);

            setCalendarArray(res);
        } catch (err) {
            Toast.fire({
                icon: 'error',
                title: err.message
            })
        } finally {
            setLoadingModalOpen(false);
            setIsUpdate(false);
            setIdCalendar('');
            handleClickClear(e);
        }
    }

    const handleClickDelete = async (e, idCalendar) => {
        e.preventDefault();
        setLoadingModalOpen(true);

        try {
            const response = await deleteCalendar(idCalendar);

            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Agendamento excluído'
                })

                const res = await getAllByIdTecnico(user.id, initDateFilter, finishDateFilter);

                setCalendarArray(res);
            }
        } catch {
            return false;
        } finally {
            setLoadingModalOpen(false);
        }

        handleClickSearch(e);
    }

    const handleClickCreate = async (e) => {
        e.preventDefault();
        setLoadingModalOpen(true);

        try {
            const parameters = {
                titulo: title,
                inicio: `${initDate}`,
                fim: `${finishDate}`,
                id_autor: user.id,
                id_tecnico: user.id,
                descricao: description,
            }

            const response = await createCalendar(parameters);

            if (response.status !== 200) {
                throw new Error('Ocorreu um erro ao tentar incluir um agendamento');
            }

            Toast.fire({
                icon: 'success',
                title: 'Agendamento salvo'
            })

            const res = await getAllByIdTecnico(user.id, initDateFilter, finishDateFilter);

            setCalendarArray(res);
        } catch (err) {
            Toast.fire({
                icon: 'error',
                title: err.message
            })
        } finally {
            setLoadingModalOpen(false);
            handleClickClear(e);
        }

        handleClickSearch(e);
    }

    const handleClickClear = async (e) => {
        e.preventDefault();

        setTitle('');
        setInitDate('');
        setFinishDate('');
        setDescription('');

        setIsListCalendar(true);
    }

    const handleClickSearch = async (e) => {
        e.preventDefault();
        setLoadingModalOpen(true);

        try {
            const res = await getAllByIdTecnico(user.id, initDateFilter, finishDateFilter);

            setCalendarArray(res);
        } catch (err) {
            Toast.fire({
                icon: 'error',
                title: 'Ocorreu um erro ao tentar buscar um agendamento'
            })

        } finally {
            setLoadingModalOpen(false);
        }
    }

    const handleClickExecute = async (e, calendar) => {

        const groupTasksTypes = calendar.tarefas.reduce((accumulator, value) => {

            const type = value.type;

            if (!accumulator[type]) {
                accumulator[type] = [];
            }

            accumulator[type].push(value);

            return accumulator;
        }, {});

        setTypes(groupTasksTypes)
        setCalendar(calendar);
        setViewTasks(true);
    }

    const handleClickSubTarefa = async (e, type) => {
        e.preventDefault();

        if (type === 'file') {
            setLoadingModalOpen(true);
            setViewReports(true);

            setReports(types['file']);
            setLoadingModalOpen(false);
        }

        if (type === 'flow') {
            setIsCalendar(true);
            setLoadingModalOpen(true);
            setViewFlows(true);

            api.get(`/order-work/${calendar.id_ordem_trabalho}/flows`).then(({ data }) => {
                setFlows(data);
                setLoadingModalOpen(false);
            }).catch((err) => {
                console.log(err);
            });
        }

        if (type === 'text') {
            setLoadingModalOpen(true);
            setViewText(true);

            api.get(`/order-work/${calendar.id_ordem_trabalho}/additional-information`).then(({ data }) => {
                setText(data?.informacoes_adicionais_interna);
                setLoadingModalOpen(false);
            }).catch((err) => {
                console.log(err);
            });
        }

        if (type === 'docs') {
            setLoadingModalOpen(true);
            setViewDocs(true);

            api.get(`/order-work/${calendar.id_ordem_trabalho}/files`).then(({ data }) => {
                setDocs(data);
                setLoadingModalOpen(false);
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const checkFinishedFlow = (flow) => {
        const answers = JSON.parse(localStorage.getItem(`otimize-respostas-order-service`));

        if (!answers) {
            return false;
        }

        const answersCompleted = answers.filter(el => el.completo === true);

        return answersCompleted.find(answer => answer.id_ordem_trabalho == flow.id_order_work && answer.id_flow == flow.id_flow);
    }

    const handleClickFlow = async (flow) => {
        if (flow.finished) {
            setFlowFinishedModalOpen(true);

            return;
        }

        navigate(`/maintenanceflow?type=order-service&id-flow=${flow.id_flow}&id-order-work=${flow.id_order_work}`);
    }

    const handleClickReport = async (report) => {
        const urls = {
            1: '/order-work/{id}/report/general/internal',
            2: '/order-work/{id}/report/equipments',
            3: '/order-work/{id}/report/supplies',
            4: '/order-work/{id}/report/displacement',
            5: '/order-work/{id}/report/accommodation',
            6: '/order-work/{id}/report/financial',
            7: '/order-work/{id}/report/travel',
            8: '/order-work/{id}/report/refund',
        }

        setLoadingModalOpen(true);

        api.get(urls[report.id_action].replace('{id}', report.id_entity), {
            responseType: 'blob',
        }).then(({ data }) => {
            const fileBlob = new Blob([data], { type: 'application/pdf' });

            const link = document.createElement('a');

            link.href = URL.createObjectURL(fileBlob);
            link.download = `${calendar.titulo} - ${report.name}.pdf`;
            link.click();

            setLoadingModalOpen(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleClickDoc = async (e, file) => {
        e.preventDefault();
        setLoadingModalOpen(true);

        const byteCharacters = atob(file.file.replace(/^data:[^;]+;base64,/, ''));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const fileBlob = new Blob(byteArrays, { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(fileBlob);
        link.download = `${calendar.titulo} - ${file.name}`;
        link.click();

        setLoadingModalOpen(false);
    }

    /*const handleDownloadDocument = async (e) => {
        e.preventDefault();
        setLoadingModalOpen(true);

        api.post('/order-work/report/download/equipments', {
            id_ordem_trabalho: 3,
        }, {
            responseType: 'blob',
        }).then(({ data }) => {
            const fileBlob = new Blob([data], { type: 'application/pdf' });
            const link = document.createElement('a');

            link.href = URL.createObjectURL(fileBlob);
            link.download = "yourname.pdf";
            link.click();

            setLoadingModalOpen(false);
        }).catch((err) => {
            console.log(err);
        });
    }*/

    return (
        <article className='h-[calc(100%-3rem)] z-10 menu rounded-md bg-gray-100 w-full mx-4 shadow-lg max-w-[420px] md:max-w-screen-sm sm:mx-auto xl:max-w-screen-lg p-3 overflow-hidden'>
            {/*<div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                <div>
                    <h2 className='text-primary-color font-bold text-xl pt-2'>Agenda</h2>
                    <h2 className='text-black text-xs pb-2'>Visualize suas atividades</h2>
                </div>
                <Link to={ navigateTo }>
                    <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full' />
                </Link>
            </div>*/}

            {
                (isListCalendar) ? (
                    (!viewTasks) ? (
                        <>
                        <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                            <div>
                                <h2 className='text-primary-color font-bold text-xl pt-2'>Agenda</h2>
                                <h2 className='text-black text-xs pb-2'>Visualize suas atividades</h2>
                            </div>
                            <Link to={ navigateTo }>
                                <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full' />
                            </Link>
                        </div>
                        <section className='h-[calc(100%-4.5rem)] menu-section text-black mt-[0.5rem] text-center menu'>
                            {/*<Filter />*/}
                            <div className='h-[8.25rem] flex flex-col justify-between'>
                                <div className='flex flex-row justify-between overflow-x-auto'>
                                    <div className=''>
                                        <label className="block text-left text-sm text-gray-700">Início</label>
                                        <div className="mt-1">
                                            <input
                                                type="datetime-local"
                                                name="data_inicial"
                                                value={initDateFilter}
                                                onChange={(e) => {
                                                    setIsFilter(true);
                                                    setInitDateFilter(e.target.value)
                                                }}
                                                className="h-[3rem] w-[180px] rounded-md border border-gray-300 bg-white text-sm focus:outline-none focus:ring-0"
                                            />
                                        </div>
                                    </div>
                                    <div className=''>
                                        <label className="block text-left text-sm text-gray-700">Término</label>
                                        <div className="mt-1">
                                            <input
                                                type="datetime-local"
                                                name="data_final"
                                                value={finishDateFilter}
                                                onChange={(e) => {
                                                    setIsFilter(true);
                                                    setFinishDateFilter(e.target.value)
                                                }}
                                                className="h-[3rem] w-[180px] rounded-md border border-gray-300 bg-white text-sm focus:outline-none focus:ring-0"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full flex flex-row justify-between mt-[0.75rem]'>
                                    <div className=''>
                                        <button 
                                            onClick={() => { setIsListCalendar(!isListCalendar); setIsUpdate(false); }} 
                                            className="h-[3rem] py-2 px-6 border border-gray-300 text-sm rounded-md text-gray-700 bg-white focus:outline-none focus:ring-0"
                                        >
                                            <div className="flex justify-center items-center">
                                                {(isListCalendar) ? 'Adicionar' : 'Minha Agenda'}
                                            </div>
                                        </button>
                                    </div>
                                    <div className=''>
                                        <button 
                                            onClick={(e) => handleClickSearch(e)}
                                            className="h-[3rem] py-2 px-6 border border-primary-color text-sm rounded-md text-white bg-primary-color focus:outline-none focus:ring-0"
                                        >
                                            Procurar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='h-[calc(100%-9rem)] mt-[0.75rem] flex flex-col justify-between overflow-hidden'>
                                <ul role="list" className="overflow-auto">
                                    {
                                        calendarArray.length > 0 ? calendarArray.map((calendar, index) => (
                                            <li key={ index } className="mb-3 bg-white shadow p-[0.75rem]">
                                                {/*<Item calendar={ calendar } />*/}
                                                <div className="block">
                                                    <div className="flex items-center">
                                                        <div className="min-w-0 flex-1 flex items-center">
                                                            <div className="min-w-0 flex-1">
                                                                <div>
                                                                    <p className="text-md font-medium text-gray-800"><strong>{calendar.titulo}</strong></p>
                                                                    <p className="mt-2 flex items-center text-left text-sm text-gray-500">
                                                                        <BiBuildings className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                        <span className=""><strong>Parceiro de Negócios:</strong> {(calendar.nome_cliente !== null) ? calendar.nome_cliente : 'Nenhum parceiro de negócios vinculado.'}</span>
                                                                    </p>
                                                                    <p className="mt-2 flex items-center text-left text-sm text-gray-500">
                                                                        <BiPhone className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                        <span className=""><strong>Contato:</strong> {(calendar.contato !== null) ? calendar.contato : 'Nenhum contato registrado.'}</span>
                                                                    </p>
                                                                    <p className="mt-2 flex items-center text-left text-sm text-gray-500">
                                                                        <BiHardHat className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                        <span className=""><strong>Técnico:</strong> {(calendar.nome_tecnico !== null) ? calendar.nome_tecnico : 'Nenhum técnico vinculado.'}</span>
                                                                    </p>
                                                                    <p className="mt-2 flex items-center text-left text-sm text-gray-500">
                                                                        <BiCalendar className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                        <span className=""><strong>De:</strong> {formatDate(calendar.inicio)} {formatTime(calendar.inicio)} <br /> <strong>Até:</strong> {formatDate(calendar.fim)} {formatTime(calendar.fim)}</span>
                                                                    </p>
                                                                    <p className="mt-2 flex items-center text-left text-sm text-gray-500">
                                                                        <BiCommentDetail className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                        <span className=""><strong>Descrição:</strong> {(calendar.descricao !== null) ? calendar.descricao : 'Nenhum descrição.'}</span>
                                                                    </p>
                                                                                                                                       
                                                                    <p className="mt-2 flex items-center text-left text-sm text-gray-500">
                                                                        <BiTask className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                        <span className=""><strong>Motivo:</strong> {(calendar.nome_tipo !== null) ? calendar.nome_tipo : 'Nenhum motivo cadastrado.'}</span>
                                                                    </p>                                                                       

                                                                    {(calendar.id_ordem_trabalho) ? (                                                                        
                                                                        <p className="mt-2 flex items-center text-left text-sm text-gray-500">
                                                                            <BiSolidMap className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                            <span className=""><strong>Endereço:</strong> {(calendar.endereco !== null) ? calendar.endereco : 'N/A'}, {(calendar.numero !== null) ? calendar.numero : 'N/A'}, {(calendar.bairro !== null) ? calendar.bairro : 'N/A'} - {(calendar.cidade !== null) ? calendar.cidade : 'N/A'} - {(calendar.uf !== null) ? calendar.uf : 'N/A'}</span>
                                                                        </p>                                                                         
                                                                    ): ''} 
                                                                    
                                                                    <p className="mt-2 flex items-center text-left text-sm text-gray-500">
                                                                        <BsFilePerson className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                        <span className=""><strong>Criado por:</strong> {calendar.nome_autor} {(calendar.id_autor == user.id) && '(Você mesmo)'}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        (calendar.id_ordem_trabalho) ? (
                                                            <div className="flex items-center justify-around mt-[1rem]">
                                                                <div className="flex flex-row flex-wrap">
                                                                    <button 
                                                                        onClick={(e) => { handleClickExecute(e, calendar); } }
                                                                        className="h-[3rem] py-2 px-6 rounded-md text-white bg-primary-color focus:outline-none focus:ring-0"
                                                                    >
                                                                        Visualizar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (calendar.id_autor == user.id) ? (
                                                            <div className="flex items-center justify-around mt-[1rem]">
                                                                <div className="flex flex-row flex-wrap">
                                                                    <button 
                                                                        onClick={(e) => handleClickAlterUpdate(e, calendar.id, calendar.titulo, calendar.inicio, calendar.fim, calendar.descricao)} 
                                                                        className="h-[3rem] py-2 px-6 rounded-md text-white bg-blue-500 focus:outline-none focus:ring-0"
                                                                    >
                                                                        Editar
                                                                    </button>
                                                                    <button 
                                                                        onClick={(e) => handleClickDelete(e, calendar.id)} 
                                                                        className="h-[3rem] py-2 px-6 rounded-md text-white bg-red-500 ml-4 focus:outline-none focus:ring-0"
                                                                    >
                                                                        Excluir
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="flex items-center justify-around mt-[0.25rem]">
                                                                <div className="flex flex-row flex-wrap"></div>
                                                            </div>
                                                        )
                                                    }
                                                    {/*
                                                        calendar.tarefas.map(task => (
                                                            <div onClick={(e) => handleClickSubTarefa(e, task)} className="cursor-pointer p-3 mt-6 bg-gray-200 shadow text-black overflow-hidden sm:rounded-md">
                                                                {task.descricao}
                                                            </div>
                                                        ))
                                                    */}
                                                </div>
                                            </li>
                                        )) : (
                                            <div className='h-full w-full text-primary-color text-center flex flex-row flex-wrap items-center justify-center p-3'>
                                                <BiBlock size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                                
                                                <h4 className='w-full font-bold text-sm xl:text-lg'>Nenhum agendamento encontrado.</h4>
                                                <p className='w-full text-sm xl:text-lg'></p>
                                            </div>
                                        )
                                    }
                                </ul>
                            </div>
                        </section>
                        </>
                    ) : (
                        /*<article className='h-[calc(100%-3rem)] rounded-md bg-gray-100 w-full mx-4 shadow-lg max-w-[420px] md:max-w-screen-sm sm:mx-auto xl:max-w-screen-lg border-primary-color p-3 z-10 overflow-hidden overflow-y-auto menu-section'>*/
                        (!viewDocs && !viewText && !viewFlows && !viewReports) ? (
                            <>
                            <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                                <div>
                                    <h2 className='text-primary-color font-bold text-xl pt-2'>Agenda</h2>
                                    <h2 className='text-black text-xs pb-2'>Visualize suas atividades</h2>
                                </div>
                                <Link onClick={() => { setViewTasks(false); }}>
                                    <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full' />
                                </Link>
                            </div>
                            <div className='w-full mt-[0.5rem] text-left'>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-sm text-[12px] text-primary-color font-medium'>
                                        { calendar.titulo }
                                    </p>
                                    <div className='h-full flex items-center text-primary-color text-sm'></div>
                                </div>
                                <p className='text-sm -mt-1 text-[12px] text-gray-800'>
                                    { calendar.nome_cliente }
                                </p>
                            </div>
                            <section className='max-h-[calc(100%-7.25rem)] grid grid-cols-2 w-full p-3 mt-[0.5rem] gap-6 md:grid-cols-3 xl:grid-cols-4 rounded-md overflow-auto menu-section'>
                                {
                                    Object.keys(types).map((type, index) => (
                                        <div key={index} onClick={(e) => handleClickSubTarefa(e, type)} className='rounded-md text-primary-color text-center shadow-md shadow-gray-300 hover:shadow-md hover:bg-primary-color hover:text-white duration-150 hover:cursor-pointer flex flex-col flex-wrap items-center justify-center hover:border-primary-color bg-gray-200 w-full hover:scale-105 p-3 aspect-square'>
                                            {
                                                (type == 'file') ? (
                                                    <div>
                                                        <BiSolidFilePdf size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                                        <h4 className='font-bold text-sm xl:text-lg'>Relatórios</h4>
                                                    </div>
                                                ) : (type == 'flow') ? (
                                                    <div>
                                                        <BiListCheck size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                                        <h4 className='font-bold text-sm xl:text-lg'>Fluxos</h4>
                                                    </div>
                                                ) : (type == 'text') ? (
                                                    <div>
                                                        <BiDetail size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                                        <h4 className='font-bold text-sm xl:text-lg'>Informações Adicionais</h4>
                                                    </div>
                                                ) : (type == 'docs') ? (
                                                    <div>
                                                        <BiFolderOpen size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                                        <h4 className='font-bold text-sm xl:text-lg'>Arquivos</h4>
                                                    </div>
                                                ) : (
                                                    <BiFile size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </section>
                            </>
                        ) : (viewText) ? (
                            <>
                            <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                                <div>
                                    <h2 className='text-primary-color font-bold text-xl pt-2'>Agenda</h2>
                                    <h2 className='text-black text-xs pb-2'>Visualize suas atividades</h2>
                                </div>
                                <Link onClick={() => { setViewText(false); setText(''); }}>
                                    <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full' />
                                </Link>
                            </div>
                            <div className='w-full mt-[0.5rem] text-left'>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-sm text-[12px] text-primary-color font-medium'>
                                        { calendar.titulo }
                                    </p>
                                    <div className='h-full flex items-center text-primary-color text-sm'></div>
                                </div>
                                <p className='text-sm -mt-1 text-[12px] text-gray-800'>
                                    { calendar.nome_cliente }
                                </p>
                            </div>
                            <textarea
                                value={text || 'Sem informações adicionais.'}
                                className='w-full h-[calc(100%-7.25rem)] mt-[0.5rem] mx-auto rounded-md border-gray-300 text-gray-900 focus:ring-0 focus:ring-offset-0 grow focus:outline-none focus:border-primary-color focus:ring-primary-color'
                                disabled={true}
                            />
                            </>
                        ) : (viewDocs) ? (
                            <>
                            <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                                <div>
                                    <h2 className='text-primary-color font-bold text-xl pt-2'>Agenda</h2>
                                    <h2 className='text-black text-xs pb-2'>Visualize suas atividades</h2>
                                </div>
                                <Link onClick={() => { setViewDocs(false); setDocs([]); }}>
                                    <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full' />
                                </Link>
                            </div>
                            <div className='w-full mt-[0.5rem] text-left'>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-sm text-[12px] text-primary-color font-medium'>
                                        { calendar.titulo }
                                    </p>
                                    <div className='h-full flex items-center text-primary-color text-sm'></div>
                                </div>
                                <p className='text-sm -mt-1 text-[12px] text-gray-800'>
                                    { calendar.nome_cliente }
                                </p>
                            </div>
                            <section className='max-h-[calc(100%-7.25rem)] grid grid-cols-2 w-full p-3 mt-[0.5rem] gap-6 md:grid-cols-3 xl:grid-cols-4 rounded-md overflow-auto menu-section'>
                                {
                                docs.length > 0 ? docs.map((doc, index) => (
                                    <div key={index} onClick={(e) => handleClickDoc(e, doc)} className='rounded-md text-primary-color text-center shadow-md shadow-gray-300 hover:shadow-md hover:bg-primary-color hover:text-white duration-150 hover:cursor-pointer flex flex-row flex-wrap items-center justify-center hover:border-primary-color bg-gray-200 w-full hover:scale-105 p-3 aspect-square'>
                                        <div className="w-full">
                                            { 
                                            (doc.type.includes('image')) ? (
                                                <BiImage size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                            ) : (doc.type.includes('pdf')) ? (
                                                <BiSolidFilePdf size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                            ) : (
                                                <BiFile size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                            )
                                            }

                                            <h4 className='w-full font-bold text-sm xl:text-lg'>{ doc.name }</h4>
                                            <p className='w-full text-sm xl:text-lg'>{ doc.description }</p>
                                        </div>
                                    </div>
                                )) : (
                                    <div className='h-full w-full col-span-2 md:col-span-3 xl:col-span-4 text-primary-color text-center flex flex-row flex-wrap items-center justify-center p-3'>
                                        <BiBlock size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                        
                                        <h4 className='w-full font-bold text-sm xl:text-lg'>Nenhum arquivo encontrado.</h4>
                                        <p className='w-full text-sm xl:text-lg'></p>
                                    </div>
                                )
                                }
                            </section>
                            </>
                        ) : (viewFlows) ? (
                            <>
                            <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                                <div>
                                    <h2 className='text-primary-color font-bold text-xl pt-2'>Agenda</h2>
                                    <h2 className='text-black text-xs pb-2'>Visualize suas atividades</h2>
                                </div>
                                <Link onClick={() => { setViewFlows(false); setFlows([]); }}>
                                    <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full' />
                                </Link>
                            </div>
                            <div className='w-full mt-[0.5rem] text-left'>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-sm text-[12px] text-primary-color font-medium'>
                                        { calendar.titulo }
                                    </p>
                                    <div className='h-full flex items-center text-primary-color text-sm'></div>
                                </div>
                                <p className='text-sm -mt-1 text-[12px] text-gray-800'>
                                    { calendar.nome_cliente }
                                </p>
                            </div>
                            <section className='max-h-[calc(100%-7.25rem)] grid grid-cols-2 w-full p-3 mt-[0.5rem] gap-6 md:grid-cols-3 xl:grid-cols-4 rounded-md overflow-auto menu-section'>
                                {
                                    flows.length > 0 ? flows.map((flow, index) => (
                                        <div key={index} onClick={() => handleClickFlow(flow)} className='rounded-md text-primary-color text-center shadow-md shadow-gray-300 hover:shadow-md hover:bg-primary-color hover:text-white duration-150 hover:cursor-pointer flex flex-row flex-wrap items-center justify-center hover:border-primary-color bg-gray-200 w-full hover:scale-105 p-3 aspect-square'>
                                            <div className="w-full">
                                                <BiFile size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                                <h4 className='w-full font-bold text-sm xl:text-lg'>{ flow.name_flow }</h4>
                                                <p className='w-full text-sm xl:text-lg'>{flow.finished ? '(Finalizado)' : checkFinishedFlow(flow) ? '(Sincronizar)' : '(Pendente)' }</p>
                                            </div>
                                        </div>
                                    )) : (
                                        <div className='text-primary-color col-span-2 md:col-span-3 xl:col-span-4 text-center flex flex-row flex-wrap items-center justify-center p-3'>
                                            <BiBlock size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                            
                                            <h4 className='w-full font-bold text-sm xl:text-lg'>Nenhum fluxo encontrado.</h4>
                                            <p className='w-full text-sm xl:text-lg'></p>
                                        </div>
                                    )
                                    }
                            </section>
                            </>
                        ) : (viewReports) ? (
                            <>
                            <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                                <div>
                                    <h2 className='text-primary-color font-bold text-xl pt-2'>Agenda</h2>
                                    <h2 className='text-black text-xs pb-2'>Visualize suas atividades</h2>
                                </div>
                                <Link onClick={() => { setViewReports(false); setReports([]); }}>
                                    <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full' />
                                </Link>
                            </div>
                            <div className='w-full mt-[0.5rem] text-left'>
                                <div className='flex flex-row items-center justify-between'>
                                    <p className='text-sm text-[12px] text-primary-color font-medium'>
                                        { calendar.titulo }
                                    </p>
                                    <div className='h-full flex items-center text-primary-color text-sm'></div>
                                </div>
                                <p className='text-sm -mt-1 text-[12px] text-gray-800'>
                                    { calendar.nome_cliente }
                                </p>
                            </div>
                            <section className='max-h-[calc(100%-7.25rem)] grid grid-cols-2 w-full p-3 mt-[0.5rem] gap-6 md:grid-cols-3 xl:grid-cols-4 rounded-md overflow-auto menu-section'>
                                {
                                    reports.length > 0 ? reports.map((report, index) => (
                                        <div key={index} onClick={() => handleClickReport(report)} className='rounded-md text-primary-color text-center shadow-md shadow-gray-300 hover:shadow-md hover:bg-primary-color hover:text-white duration-150 hover:cursor-pointer flex flex-col flex-wrap items-center justify-center hover:border-primary-color bg-gray-200 w-full hover:scale-105 p-3 aspect-square'>
                                            <div className="w-full">
                                                <BiSolidFilePdf size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                                <h4 className='w-full font-bold text-sm xl:text-lg'>{ report.name }</h4>
                                                <p className='w-full text-sm xl:text-lg'></p>
                                            </div>
                                        </div>
                                    )) : (
                                        <div className='h-full w-full text-primary-color text-center flex flex-row flex-wrap items-center justify-center p-3'>
                                            <BiBlock size='2.25em' className='mx-auto mb-3 text-xl xl:text-2xl' />
                                            
                                            <h4 className='w-full font-bold text-sm xl:text-lg'>Nenhum relatório encontrado.</h4>
                                            <p className='w-full text-sm xl:text-lg'></p>
                                        </div>
                                    )
                                }
                            </section>
                            </>
                        ) : (
                            <></>
                        )
                        /*</article>*/
                    )
                ) : (
                    <>
                    <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
                        <div>
                            <h2 className='text-primary-color font-bold text-xl pt-2'>Agenda</h2>
                            <h2 className='text-black text-xs pb-2'>Visualize suas atividades</h2>
                        </div>
                        <Link onClick={() => { setIsListCalendar(true); setViewTasks(false); }}>
                            <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full' />
                        </Link>
                    </div>
                    <div className="mt-[0.5rem] h-[calc(100%-4.5rem)] flex flex-col justify-between">
                        <div className="w-full">
                            <div className="">
                                <label className="block text-sm font-medium text-gray-700">Título (*)</label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="titulo"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="mt-[0.5rem]">
                                <label className="block text-sm font-medium text-gray-700">Data e Horário Inicial (*)</label>
                                <div className="mt-1">
                                    <input
                                        type="datetime-local"
                                        name="data_limite"
                                        value={initDate}
                                        onChange={(e) => setInitDate(e.target.value)}
                                        className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="mt-[0.5rem]">
                                <label className="block text-sm font-medium text-gray-700">Data e Horáriro Final (*)</label>
                                <div className="mt-1">
                                    <input
                                        type="datetime-local"
                                        name="data_final"
                                        value={finishDate}
                                        onChange={(e) => setFinishDate(e.target.value)}
                                        className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="mt-[0.5rem]">
                                <label className="block text-sm font-medium text-gray-700">Descrição</label>
                                <div className="mt-1">
                                    <textarea
                                        rows={6}
                                        type="text"
                                        name="descricao"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="text-black shadow-sm focus:ring-primary-color focus:border-primary-color block w-full sm:text-sm border-gray-300 rounded-md"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <button 
                                onClick={(e) => handleClickClear(e)} 
                                className="h-[3rem] bg-white py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-0 focus:ring-primary-color"
                            >
                                Cancelar
                            </button>
                            <button 
                                onClick={(e) => (isUpdate) ? handleClickUpdate(e, idCalendar) : handleClickCreate(e)} 
                                className="h-[3rem] bg-primary-color py-2 px-6 border border-primary-color rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-0 focus:ring-primary-color"
                            >
                                {isUpdate ? 'Atualizar' : 'Salvar'}
                            </button>
                        </div>
                    </div>
                    </>
                )
            }
        </article >
    )
}