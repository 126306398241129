import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import api from './../services/api';
import { BsArrowLeft } from 'react-icons/bs';
import { MdQrCodeScanner } from 'react-icons/md';
import { SlCamera } from 'react-icons/sl';
import { QrReader } from 'react-qr-reader';

import MainContext from './../context/MainContext';
import ModalContext from './../context/ModalContext';

import ClientComboboxComponent from './../components/layout/Combobox/ClientCombobox';
import OrderWorkComboboxComponent from './../components/layout/Combobox/OrderWorkCombobox';
import EquipamentComboboxComponent from './../components/layout/Combobox/EquipamentCombobox';
import FlowComboboxComponent from './../components/layout/Combobox/FlowCombobox';

import FilesArrayModal from './../components/modals/FilesArrayModal';

import CheckboxAnswer from './layout/Flow/CheckboxAnswer';
import TextAnswer from './layout/Flow/TextAnswer';
import NumberAnswer from './layout/Flow/NumberAnswer';
import DateAnswer from './layout/Flow/DateAnswer';
import TimeAnswer from './layout/Flow/TimeAnswer';
import DatetimeAnswer from './layout/Flow/DatetimeAnswer';
import RadioAnswer from './layout/Flow/RadioAnswer';
import SignatureAnswer from './layout/Flow/SignatureAnswer';

import { toBase64 } from './../services/image';

import './style.css';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function FlowMenu({ type, idFlow, idOrderWork }) {
  // CONTEXT
  const {
    equipamentArray,
    flowArray, setFlowArray,
    selectedFlow,
    selectedEquipament,
    setSelectedFlow,
    setSelectedEquipament,
    handleResetValues,
    downloadButton,
    selectedQuestion,
    setSelectedQuestion,
    dateValue, 
    timeValue, 
    datetimeValue, 
    textValue, 
    numberValue,    
    checkedBoxes, 
    checkedRadio,
    otherRadioValue,
    signatureValue,
    startLoopValue,
    endLoopValue,
    isFlowComplete,
    setIsFlowComplete,
    questionIndex,
    setQuestionIndex,
    reloadedFlow,
    setReloadedFlow,
    observationsArray,
    setObservationsArray,
    filesArray,
    setFilesArray,
    filesSavedArray, 
    setFilesSavedArray,
    hasLoadedFlow, setHasLoadedFlow,
    hasLoadedEquipament, setHasLoadedEquipament,
    isCalendar, 
    setIsCalendar,
  } = useContext(MainContext);

  const {
    setDownloadFlowModalOpen,
    // reloadFlowModalOpen,
    setReloadFlowModalOpen,
    loadingModalOpen,
    setLoadingModalOpen,
    setSyncAnswerModalOpen,
    setFilesArrayModalOpen,
    setAnswerNotResponseModalOpen,
    answerNotResponseModalOpen,
    setAnswerNotFileModalOpen,
    answerNotFileModalOpen,
    setFlowFinishedModalOpen,
    flowFinishedModalOpen,
  } = useContext(ModalContext);
  
  // STATE
  const [isAnswering, setIsAnswering] = useState(false);
  const [startErrorMessage, setStartErrorMessage] = useState(false);
  const [syncErrorMessage, setSyncErrorMessage] = useState(false);
  const [syncConflictErrorMessage, setSyncConflictErrorMessage] = useState(false);
  const [flowCompleteMessage, setFlowCompleteMessage] = useState(false);
  const [readerQR, setReaderQR] = useState(false);
  const [config, setConfig] = useState({});

  const [loops, setLoops] = useState([]);

  // HOOKS
  useEffect(() => {
    if (flowCompleteMessage) {
      setTimeout(() => setFlowCompleteMessage(false), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowCompleteMessage])

  useEffect(() => {
    if (syncConflictErrorMessage) {
      setTimeout(() => setSyncConflictErrorMessage(false), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncConflictErrorMessage])

  useEffect(() => {
    if (syncErrorMessage) {
      setTimeout(() => setSyncErrorMessage(false), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncErrorMessage])

  useEffect(() => {
    if (startErrorMessage) {
      setTimeout(() => setStartErrorMessage(false), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startErrorMessage])

  useEffect(() => {
    if (answerNotResponseModalOpen) {
      setTimeout(() => setAnswerNotResponseModalOpen(false), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerNotResponseModalOpen])

  useEffect(() => {
    if (answerNotFileModalOpen) {
      setTimeout(() => setAnswerNotFileModalOpen(false), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerNotFileModalOpen])

  useEffect(() => {
    if (flowFinishedModalOpen) {
      setTimeout(() => setFlowFinishedModalOpen(false), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowFinishedModalOpen])

  useEffect(() => {
    const respostas = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));

    if (!respostas) {
      return
    }

    const completedFlows = respostas.filter(el => el.completo === true);

    if (completedFlows.length > 0) {
      setIsFlowComplete(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    setStartErrorMessage(false);

    if (readerQR) {
      setReaderQR(false);
      verifyStartFlow();
    }
  }, [selectedEquipament, selectedFlow]);

  useEffect(() => {
    if (reloadedFlow === false) {
      startNewFlow();
      setReloadedFlow(null);

      return
    }

    if (reloadedFlow === true) {
      reloadStartedFlow();
      setReloadedFlow(null);

      return
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadedFlow]);

  useEffect(() => {
    if (!selectedFlow) {
      return;
    }

    if (questionIndex === null) {
      setSelectedQuestion(selectedFlow.perguntas.at(0));

      return;
    }

    const answer = selectedFlow.perguntas.find(el => el.id === questionIndex);
    setSelectedQuestion(answer);
  }, [questionIndex]);

  useEffect(() => {
    handleConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasLoadedFlow || !hasLoadedEquipament) {
      return;
    }

    if (!idFlow || !idOrderWork) {
      return;
    }

    const flow = flowArray.find(flow => flow.id == idFlow);
    const orderWork = equipamentArray.find(orderWork => orderWork.id == idOrderWork);

    setSelectedFlow(flow);
    setSelectedEquipament(orderWork);

    setHasLoadedFlow(false);
    setHasLoadedEquipament(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoadedFlow, hasLoadedEquipament]);

  useEffect(() => {
    if (!isCalendar || !selectedFlow || !selectedEquipament) {
      return;
    }

    // setIsCalendar(false);
    verifyStartFlow();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlow, selectedEquipament]);

  // FUNCTIONS

  // iniciar um fluxo de perguntas
  function startNewFlow() {
    if (!selectedFlow || !selectedEquipament) {
      return;
    }

    // Verifica se fluxo tem perguntas do tipo loop
    setLoops([]);

    // Valida se a primeira pergunta do fluxo é um início de repetição
    const question = selectedFlow.perguntas.at(0);

    setSelectedQuestion(question);
    setQuestionIndex(null);

    setFilesArray([]);
    setObservationsArray([]);

    const executionFlow = JSON.stringify({
      order_work: selectedEquipament,
      flow: selectedFlow,
    });

    const answerObject = {
      id_ordem_trabalho: selectedEquipament.id,
      id_projeto: selectedEquipament.id_projeto,
      id_flow: selectedFlow.id,
      respostas: [],
      completo: false,
    }

    const respostas = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));
    
    const answerArray = [];

    if (respostas) {
      const savedFlows = respostas.filter(el => !(el.id_ordem_trabalho === selectedEquipament.id && el.id_flow === selectedFlow.id))
      
      savedFlows.forEach(el => answerArray.push(el))
    }
    
    answerArray.push(answerObject)

    localStorage.setItem(`otimize-execucao-${ type }`, executionFlow);

    localStorage.setItem(`otimize-respostas-${ type }`, JSON.stringify(answerArray));

    setIsAnswering(true);    
 
    return;
  }

  // retomar o fluxo de perguntas
  function reloadStartedFlow () {
    const answers = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));

    if (!answers) {
      return
    }

    const executionFlow = JSON.stringify({
      order_work: selectedEquipament,
      flow: selectedFlow,
    });

    const currentFlow = answers.find(el => el.id_ordem_trabalho === selectedEquipament.id && el.id_flow === selectedFlow.id);

    localStorage.setItem(`otimize-execucao-${ type }`, executionFlow);

    setFilesArray([]);
    setObservationsArray([]);

    if (currentFlow.respostas && currentFlow.respostas.length === 0) {
      const question = selectedFlow.perguntas.at(0);
      
      setSelectedQuestion(question);
      setQuestionIndex(question.id);
      setIsAnswering(true);

      return;
    }

    const questionId = currentFlow.respostas.at(-1).id_pergunta;
    const question = selectedFlow.perguntas.find(el => el.id === questionId);
    
    setSelectedQuestion(question);
    setQuestionIndex(question.id);
    setIsAnswering(true);
  }

  // verificar existência de fluxo em andamento
  function verifyStartFlow() {
    if (!selectedFlow || !selectedEquipament) {
      setStartErrorMessage(true);

      return;
    }

    const respostasDoLocalStorage = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));

    if (!respostasDoLocalStorage) {
      startNewFlow();

      return
    }
    
    const currentFlow = respostasDoLocalStorage.find(el => el.id_ordem_trabalho === selectedEquipament.id && el.id_flow === selectedFlow.id);
    
    if (!currentFlow) {
      startNewFlow();

      return
    }

    if (currentFlow.id_ordem_trabalho === selectedEquipament.id && currentFlow.id_flow === selectedFlow.id) {
      if (currentFlow.completo) {
        setFlowCompleteMessage(true);

        return
      }

      setReloadFlowModalOpen(true);

      return
    }

    startNewFlow();

    return
  }

  // resetar os valores das variáveis com valor da resposta
  function handleReset () {
    setSelectedFlow(null);
    setSelectedEquipament(null);
  }

  function getResponseType(type) {
    if (type === 'checkbox' && checkedBoxes.length > 0) {
      return checkedBoxes;
    }

    if (type === 'text') {
      return textValue.trim();
    }

    if (type === 'number') {
      return numberValue;
    }

    if (type === 'date') {
      return dateValue;
    }

    if (type === 'time') {
      return timeValue;
    }

    if (type === 'datetime') {
      return datetimeValue;
    }

    if (type === 'radio' && checkedRadio === 'Outro') {
      return otherRadioValue.trim();
    }

    if (type === 'radio') {
      return checkedRadio;
    }

    if (type === 'signature') {
      return signatureValue;
    }

    if (type === 'start-loop') {
      return startLoopValue;
    }

    if (type === 'end-loop') {
      return endLoopValue;
    }

    return false;
  }

  function goNext() {
    if (Boolean(selectedQuestion.response_required) && !getResponseType(selectedQuestion.tipo)) {
      setAnswerNotResponseModalOpen(true);

      return false;
    }

    // IF Feito apenas para tratar situação específica do cliente
    if (
      Boolean(selectedQuestion.file_required) && 
      filesArray.length === 0 && 
      filesSavedArray.length === 0 &&
      selectedFlow.nome === 'ATENDIMENTO AO CLIENTE' &&
      selectedQuestion.pergunta === 'Ressalvas' &&
      getResponseType(selectedQuestion.tipo) === 'Não'
    ) {
      setAnswerNotResponseModalOpen(false);
      setAnswerNotFileModalOpen(false);

      return true;
    }

    if (Boolean(selectedQuestion.file_required) && filesArray.length === 0 && filesSavedArray.length === 0) {
      setAnswerNotFileModalOpen(true);

      return false;
    }

    setAnswerNotResponseModalOpen(false);
    setAnswerNotFileModalOpen(false);

    return true;
  }

  // salvar cada resposta no local storage
  function saveAnswerToStorage () {
    const respostasDoLocalStorage = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));
    const savedFlows = respostasDoLocalStorage.filter(el => !(el.id_ordem_trabalho === selectedEquipament.id && el.id_flow === selectedFlow.id));
    const currentFlow = respostasDoLocalStorage.find(el => el.id_ordem_trabalho === selectedEquipament.id && el.id_flow === selectedFlow.id);
    const answers = currentFlow.respostas.filter(el => el.id_pergunta !== selectedQuestion.id);
    const files = [];

    // Adiciona todos os arquivos já salvos
    filesSavedArray.forEach(file => {
      files.push(file);
    });

    filesArray.forEach((file, index) => {
      const fileName = `otimize-anexo-${currentFlow.id_ordem_trabalho}-${currentFlow.id_flow}-${selectedQuestion.id}-${filesSavedArray.length + index}`;
      
      toBase64(file).then(res => {
        localStorage.setItem(fileName, JSON.stringify(res));

        return;
      });

      files.push({
        arquivo: fileName,
        comentario: observationsArray[index]
      });
    });

    answers.push({
      id_pergunta: selectedQuestion.id,
      resposta: getResponseType(selectedQuestion.tipo),
      anexo: files
    });

    const newObj = {
      id_ordem_trabalho: currentFlow.id_ordem_trabalho,
      id_projeto: currentFlow.id_projeto,
      id_flow: currentFlow.id_flow,
      respostas: answers,
      completo: false
    }

    savedFlows.push(newObj);

    localStorage.setItem(`otimize-respostas-${ type }`, JSON.stringify(savedFlows));

    setFilesArray([]);
    setObservationsArray([]);
  }

  function handleAnswer () {
    const respostas = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));

    const currentFlow = respostas.find(el => (el.id_ordem_trabalho === selectedEquipament.id) && (el.id_flow === selectedFlow.id));

    const answer = currentFlow.respostas.find(el => el.id_pergunta === selectedQuestion.id);

    switch (selectedQuestion.tipo) {
      case 'radio':
        return (<RadioAnswer question={selectedQuestion} answer={answer} />)
      case 'checkbox':
        return (<CheckboxAnswer question={selectedQuestion} answer={answer} />)
      case 'text':
        return (<TextAnswer question={selectedQuestion} answer={answer} />)
      case 'number':
        return (<NumberAnswer question={selectedQuestion} answer={answer} />)
      case 'date':
        return (<DateAnswer question={selectedQuestion} answer={answer} />)
      case 'time':
        return (<TimeAnswer question={selectedQuestion} answer={answer} />)
      case 'datetime':
        return (<DatetimeAnswer question={selectedQuestion} answer={answer} />)
      case 'signature':
        return (<SignatureAnswer question={selectedQuestion} answer={answer} />)
    }
  }

  function getIndex() {
    //console.log('question index: ', questionIndex)
    const index = selectedFlow.perguntas.findIndex(el => el.id === questionIndex);
    //console.log('index: ', index)
    //console.log('flow: ', selectedFlow)
    return (index < 0 ? 0 : index);
  }

  function setIndex(step) {
    //console.log(step)
    const nextIndex = getIndex() + step;
    //console.log(nextIndex)
    const answer = selectedFlow.perguntas.at(nextIndex);

    setQuestionIndex(answer.id);
  }

  // retornar section de acordo com a pergunta
  function handleQuestionSection() {
    return (
      <section className='flex flex-col h-full justify-between'>
        <div className='text-left'>
          <div className='flex flex-row items-center justify-between'>
            <p className='text-sm text-[12px] text-primary-color font-medium'>
              { selectedEquipament.numero_ordem_trabalho }
            </p>
            <div className='h-full flex items-center text-primary-color text-sm'>{ getIndex() + 1 } / { selectedFlow.perguntas.length }</div>
          </div>
          <p className='text-sm -mt-1 text-[12px]'>
            { selectedFlow.nome }
          </p>
        </div>
        <div className='my-1'>
          <h2 className='text-primary-color font-medium text-[20px]'>
            {selectedQuestion.pergunta}
          </h2>
          <h4 className='text-sm -mt-1'>
            {selectedQuestion.description}
          </h4>
          {/*<h4 className='text-sm -mt-1'>
            Categoria: {selectedQuestion.nome_categoria}
          </h4>*/}
        </div>
        <aside className='grow h-full overflow-auto'>
          {handleAnswer()}
        </aside>
        <FilesArrayModal />
        <div className='flex w-full justify-center'>
          <SlCamera
            onClick={ () => setFilesArrayModalOpen(true) }
            className='text-primary-color text-5xl p-1 mx-4 hover:scale-110 duration-150' 
          />
        </div>
        <div className='flex w-full justify-between'>
            {
              questionIndex === null || questionIndex === selectedFlow.perguntas.at(0).id ? (
                <button
                  onClick={(e) => {
                    handleResetValues();
                    setIsAnswering(!isAnswering);
                  }}
                  className='h-[3rem] bg-white border border-primary-color text-primary-color py-1 px-4 text-lg sm:text-base rounded-md hover:bg-primary-color hover:text-white'
                >                
                  Cancelar
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    saveAnswerToStorage();
                    handleResetValues();
                    setIndex(-1);
                  }}
                  className='h-[3rem] bg-white border border-primary-color text-primary-color py-1 px-4 text-lg sm:text-base rounded-md hover:bg-primary-color hover:text-white'
                >                
                  Voltar
                </button>
              )
            }
            {
              ((questionIndex === selectedFlow.perguntas.at(-1).id) || (questionIndex === null && selectedFlow.perguntas.length === 1)) ? (
                <button
                  onClick={(e) => {
                    if (!goNext()) {
                      return;
                    }

                    saveAnswerToStorage();
                    handleFinishedFlow();
                    handleResetValues();
                    setIsAnswering(!isAnswering);
                  }}
                  className='h-[3rem] bg-white border border-primary-color text-primary-color py-1 px-4 text-lg sm:text-base rounded-md hover:bg-primary-color hover:text-white'
                >
                  Finalizar
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    if (!goNext()) {
                      return;
                    }
                    
                    saveAnswerToStorage();
                    handleResetValues();
                    setIndex(1)
                  }}
                  className='h-[3rem] bg-white border border-primary-color text-primary-color py-1 px-4 text-lg sm:text-base rounded-md hover:bg-primary-color hover:text-white'
                >                
                  Avançar
                </button>
              )
            }
        </div>
      </section>
    )
  }

  // Baixar fluxos
  function handleDownloadFlow() {
    if (!selectedFlow || !selectedEquipament) {
      setStartErrorMessage(true)
      return
    }
    
    setDownloadFlowModalOpen(true);
    
    const flows = JSON.parse(localStorage.getItem(`otimize-flows-${ type }`));
    
    if (!flows) {
      const flow = [ { order_work: selectedEquipament, flows: [ selectedFlow ]}]
      
      localStorage.setItem(`otimize-flows-${ type }`, JSON.stringify(flow))
      return
    }
    
    const foundEquipament = flows.find(el => el.order_work.id === selectedEquipament.id);
    
    if (!foundEquipament) {
      const newFlows =  [...flows, { order_work: selectedEquipament, flows: [ selectedFlow ] }]
      
      localStorage.setItem(`otimize-flows-${ type }`, JSON.stringify(newFlows));
      
      return
    }
    
    const foundFlows = foundEquipament.flows.filter(el => el.id !== selectedFlow.id);

    const newFlowsv2 = flows.filter(el => el.order_work.id !== selectedEquipament.id);    
    
    const newFoundFlows = [...foundFlows, selectedFlow];    
    
    const newFlows =  [...newFlowsv2, { order_work: selectedEquipament, flows: newFoundFlows }];
    
    localStorage.setItem(`otimize-flows-${ type }`, JSON.stringify(newFlows));
    
    return    
  }  

  // finalizar o fluxo
  function handleFinishedFlow() {
    const answers = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));

    const savedFlows = answers.filter(el => !(el.id_ordem_trabalho === selectedEquipament.id && el.id_flow === selectedFlow.id));

    const currentFlow = answers.find(el => el.id_ordem_trabalho === selectedEquipament.id && el.id_flow === selectedFlow.id);

    const newAnswer = {
      id_ordem_trabalho: currentFlow.id_ordem_trabalho,
      id_projeto: currentFlow.id_projeto,
      id_flow: currentFlow.id_flow,
      respostas: currentFlow.respostas,
      completo: true
    };

    savedFlows.push(newAnswer);

    localStorage.setItem(`otimize-respostas-${ type }`, JSON.stringify(savedFlows));

    setIsFlowComplete(true);

    setIsAnswering(false);
  }

  // salvar o fluxo completo na API
  async function handleSaveToAPI () {
    setSyncErrorMessage(false);
    setSyncConflictErrorMessage(false);
    setLoadingModalOpen(true);
    // chamar a API
    const answers = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));

    const answersCompleted = answers.filter(el => el.completo === true);

    answersCompleted.forEach(answer => {
      const respostas = answer.respostas.filter(el => el.anexo.length > 0);

      const anexosArray = [];

      respostas.forEach(resposta => {
        resposta.anexo.forEach(anexo => {
          anexosArray.push({
            id_pergunta: resposta.id_pergunta,
            nome: anexo.arquivo,
            comentario: anexo.comentario,
            arquivo: localStorage.getItem(anexo.arquivo)
          });
        });
      });

      api.post('/maintenance/save', {
        respostas: answer,
        anexos: anexosArray
      }).then(res => {
        if (res.status === 200) {
          const respostas = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));

          const savedAnswers = respostas.filter(el => !(el.id_ordem_trabalho === answer.id_ordem_trabalho && el.id_flow === answer.id_flow));
  
          localStorage.setItem(`otimize-respostas-${ type }`, JSON.stringify(savedAnswers));

          anexosArray.forEach(anexo => {
            localStorage.removeItem(anexo.nome);
          });

          setLoadingModalOpen(false);
          setSyncAnswerModalOpen(true);
        }
      }).catch(err => {
        setLoadingModalOpen(false);
        setIsFlowComplete(true);

        if (err?.response?.status === 409) {
          const respostas = JSON.parse(localStorage.getItem(`otimize-respostas-${ type }`));
  
          const savedAnswers = respostas.filter(el => !(el.id_ordem_trabalho === answer.id_ordem_trabalho && el.id_flow === answer.id_flow));
  
          localStorage.setItem(`otimize-respostas-${ type }`, JSON.stringify(savedAnswers));

          anexosArray.forEach(anexo => {
            localStorage.removeItem(anexo.nome);
          });

          setIsFlowComplete(false);
          setSyncConflictErrorMessage(true);

          return;
        }

        setSyncErrorMessage(true);

        return
      });
    });

    setIsFlowComplete(false);
  }

  async function handleReaderQR(scanData) {
    if (!scanData || loadingModalOpen) {
      return;
    }

    setLoadingModalOpen(true);

    const qrCode = JSON.parse(scanData.text);
    const equipament = equipamentArray.find(el => el.id === qrCode.id);

    if (equipament) {
      const flows = await api.get(`/maintenance-flows/equipament/${equipament.id}`);

      if (flows.status !== 200) {
        setStartErrorMessage(true);
        setLoadingModalOpen(false);

        return;
      }

      const flow = flows.data.filter(fluxo => {
        fluxo.perguntas = fluxo.perguntas.filter(pergunta => pergunta.status == true);

        if (fluxo.perguntas.length === 0) {
          return false;
        }

        return fluxo;
      }).find(el => el.id === 3);

      setSelectedEquipament(equipament);
      setSelectedFlow(flow);

      setLoadingModalOpen(false);
    }
  }

  async function handleConfig() {
    if (type === 'order-service') {
      setConfig({
        title: 'Ordem de Trabalho',
        subTitle: 'Executar um fluxo de ordem de trabalho',
        description: 'Selecione uma ordem de trabalho e qual fluxo que deseja executar',
        noClients: 'Não há ordens de trabalho cadastradas.',
        noFlows: 'Não há fluxos cadastrados.',
      });

      return;
    }

    /*if (type === 'daily-activities') {
      setConfig({
        title: 'Diário de Atividades',
        subTitle: 'Lançar atividades',
        description: 'Selecione um parceiro de negócios e qual fluxo que deseja executar para iniciar um lançamento de atividade',
        noClients: 'Não há parceiros de negócios cadastrados.',
        noFlows: 'Não há fluxos cadastrados.',
      });

      return;
    }

    if (type === 'follow-up-project') {
      setConfig({
        title: 'Acompanhamento de Projetos',
        subTitle: 'Lançar atividades',
        description: 'Selecione um projeto e qual fluxo que deseja executar para iniciar um lançamento de atividade',
        noClients: 'Não há parceiros de negócios com projetos em andamento.',
        noFlows: 'Não há fluxos cadastrados.',
      });

      return;
    }*/
  }

  return (
    <>
      <article className='h-[calc(100%-3rem)] z-10 menu rounded-md bg-gray-100 w-full mx-4 shadow-lg max-w-[420px] md:max-w-screen-sm sm:mx-auto xl:max-w-screen-lg p-3 overflow-hidden'>
        <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
          <div>
            <h2 className='text-primary-color font-bold text-xl pt-2'>{ config.title }</h2>
            <h2 className='text-black text-xs pb-2'>{ config.subTitle }</h2>
          </div>
          <Link to='/calendar' onClick={ () => handleReset() }>
            <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full'/>        
          </Link>
        </div>
        {
          readerQR === false
          ? (
              <section className='h-[calc(100%-4.5rem)] overflow-auto menu-section text-black mt-[0.5rem] text-center menu'>
                {
                  isAnswering
                  ? (
                    handleQuestionSection()
                  )
                  : (
                    <div className='h-full flex flex-col justify-between'>
                      
                      <div className='w-full flex flex-col items-center max-w-[768px] mx-auto'>
                        <h2 className='text-black text-sm pb-4'>{ config.description }</h2>
                        <article className='w-full py-2'>
                          {
                            equipamentArray.length > 0
                            ? (
                              <OrderWorkComboboxComponent />
                            )
                            : (
                              <span>{ config.noClients }</span>
                            )
                          }
                        </article>
                        <article className='w-full py-2'>
                        {
                            flowArray.length > 0
                            ? (
                              <FlowComboboxComponent />
                            )
                            : (
                              <span>{ config.noFlows }</span>
                            )
                          }
                        </article>
                        <aside className='text-center mx-auto items-center w-full py-4 flex w-full justify-between'>                    
                          <button
                            disabled={!downloadButton}
                            onClick={ () => handleDownloadFlow() }
                            className={classNames('h-[3rem] bg-white border py-1 px-4 text-base rounded-md', downloadButton ? 'border-primary-color text-primary-color hover:bg-primary-color hover:text-white' : 'border-gray-200 text-gray-200')}
                          >
                            Baixar fluxo
                          </button>
                          <button
                            onClick={ () => verifyStartFlow() }
                            className='h-[3rem] bg-white border border-primary-color text-primary-color py-1 px-4 text-base rounded-md hover:bg-primary-color hover:text-white'
                          >
                            Iniciar fluxo
                          </button>
                        </aside>
                        <div>
                          {
                            startErrorMessage 
                            ? (
                              <p className='text-red-500 text-sm mt-2'>Por favor, selecione uma ordem de trabalho e um fluxo para baixar ou inciar.</p>
                            ) :
                            syncErrorMessage 
                            ? (
                              <p className='text-red-500 text-sm mt-2'>Ocorreu um erro ao tentar sincronizar as respostas, por favor, tente novamente dentro de alguns instantes.</p>
                            ) :
                            syncConflictErrorMessage 
                            ? (
                              <p className='text-red-500 text-sm mt-2'>Este fluxo para essa ordem de trabalho já foi respondido e sincronizado anteriormente.</p>
                            ) :
                            flowCompleteMessage 
                            && (
                              <p className='text-red-500 text-sm mt-2'>Este fluxo para essa ordem de trabalho já está finalizado. Execute a sincronização das respostas.</p>
                            )
                          }
                        </div>
                        {
                          isFlowComplete && (
                            <aside className='text-center mx-auto items-center w-full pt-4 flex w-full justify-center'>                    
                              <button
                                disabled={!downloadButton}
                                onClick={ () => handleSaveToAPI() }
                                className={classNames('h-[3rem] bg-white border py-1 px-4 text-lg rounded-md', downloadButton ? 'border-primary-color text-primary-color hover:bg-primary-color hover:text-white' : 'border-gray-200 text-gray-200')}
                              >
                                Sincronizar Respostas
                              </button>                    
                            </aside>
                          )
                        }
                        
                      </div>
                      <div className='w-full flex justify-center items-center py-4 max-w-[768px] mx-auto'>
                          {
                            (downloadButton !== false && equipamentArray.length > 0)
                            && (
                              <MdQrCodeScanner 
                                className='text-primary-color text-5xl p-1 hover:scale-110 duration-150' 
                                onClick={ () => setReaderQR(true) }
                              />
                            )
                          }
                      </div>
                    </div>
                  )
                }
              </section>
          ) : (
            <section className='h-[calc(100%-4rem)] overflow-auto menu-section text-black pt-[1rem] pb-[1rem] text-center menu'>
              <div className='h-full flex flex-col items-center'>
                <div className='w-full flex flex-col items-center px-2 max-w-[768px] mx-auto'>
                  <h2 className='text-black text-sm pb-4'>Escaneie o QR Code da ordem de trabalho que deseja executar/iniciar</h2>
                  <article className='w-full py-2 flex justify-center'>
                    <QrReader
                      constraints={{ facingMode: 'environment' }}
                      scanDelay={ 1000 }
                      onResult={ handleReaderQR }
                      className='border border-gray-300 rounded-md shadow-sm'
                      videoStyle={{ top: '0px', left: '0px', display: 'block', position: 'absolute', overflow: 'hidden', width: '280px', height: '280px', objectFit: 'cover', zIndex: -1 }}
                      containerStyle={{ overflow: 'hidden', position: 'relative', width: '280px', height: '280px' }}
                      videoContainerStyle={{ top: '0px', left: '0px', boxSizing: 'border-box', border: '30px solid rgba(0, 0, 0, 0.3)', boxShadow: 'rgb(90, 26, 146) 0px 0px 0px 5px inset', position: 'initial', width: '280px', height: '280px', paddingTop: '0px' }}
                    />
                  </article>
                  <aside className='text-center mx-auto items-center py-4 flex items-center justify-center w-full'>                    
                    <button
                      onClick={ () => setReaderQR(false) }
                      className={'h-[3rem] bg-white border py-1 px-4 text-base rounded-md border-primary-color text-primary-color hover:bg-primary-color hover:text-white'}
                    >
                      Cancelar
                    </button>
                  </aside>
                  <div>
                    {
                      startErrorMessage 
                      ? (
                        <p className='text-red-500 text-sm mt-2'>QR Code inválido. Por favor, verifique a ordem de trabalho e tente novamente</p>
                      ) :
                      flowCompleteMessage 
                      && (
                        <p className='text-red-500 text-sm mt-2'>Essa ordem de trabalho já está com esse fluxo executado. Verifique a sincronização das respostas para liberar uma nova execução</p>
                      )
                    }
                  </div>
                </div>
              </div>
            </section>
          )
        }
      </article>    
    </>
  )
}