import React from 'react'
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import MainContext from './../../../context/MainContext';

export default function RadioAnswer ({ question, answer }) {
  const { checkedRadio, setCheckedRadio, otherRadioValue, setOtherRadioValue, setFilesSavedArray } = useContext(MainContext);

  const [other, setOther] = useState(false);

  useEffect(() => {
    handleCheck();
  }, [question]);

  function handleCheck() {
    setOther(false);
    setOtherRadioValue('');

    if (!answer || !answer.resposta) {
      return;
    }

    answer.anexo.forEach(file => {
      setFilesSavedArray(files => [...files, file]);
    });

    if (!question.respostas.includes(answer.resposta)) {
      setOther(true);
      setCheckedRadio('Outro');
      setOtherRadioValue(answer.resposta);

      return;
    }

    setCheckedRadio(answer.resposta);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function handleChange (value) {
    setCheckedRadio(value)
    if (value === 'Outro') {      
      setOther(true)
    } else {
      setOther(false)
      setOtherRadioValue('')
    }
  }

  return (
    <div className='flex flex-col justify-center w-full max-w-[400px] mx-auto text-left text-2xl md:text-base'>
        {
          question.respostas && question.respostas.map((el, index) => (
            <div key={index} className='flex flex-row items-center'>
              <label htmlFor={`radio-${el}`}>
                <input
                  checked={ checkedRadio === el ? true : false }
                  onChange={(event) => handleChange(event.target.value)}
                  id={`radio-${el}`}
                  type="radio"
                  value={el}
                  key={`${question.ordem}-${question.id}-${index}`} 
                  name={`${question.ordem}-${question.id}-${index}`}
                  className='mx-2 mr-4 h-[2rem] w-[2rem] my-4 border-gray-300 text-primary-color focus:ring-0 focus:ring-offset-0'
                />
                {el !== 'Outro' && el}
              </label>
                {
                  el === 'Outro'
                  && (
                    <input                      
                      disabled={!other}
                      onChange={(e) => setOtherRadioValue(e.target.value)}
                      className={classNames('h-[3rem] w-full mr-2 rounded-md border-gray-300 focus:ring-0 focus:ring-offset-0 focus:outline-none focus:border-primary-color focus:ring-primary-color text-base',
                      !other && 'opacity-40')}
                      type="text"
                      value={otherRadioValue}
                      placeholder="Outro"
                    />
                  )
                }
            </div>
          ))
        }
      </div>
  )
}