import { Fragment, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ModalContext from './../../context/ModalContext';
import MainContext from './../../context/MainContext';

export default function AddFileModal() {
    const { filesArrayModalOpen, setFilesArrayModalOpen } = useContext(ModalContext);
    const { filesArray, setFilesArray, observationsArray, setObservationsArray } = useContext(MainContext);

    const [newFileUrlToAdd, setNewFileUrlToAdd] = useState([]);
    const [newFileObservationToAdd, setNewFileObservationToAdd] = useState('');

    const [confirmFiles, setConfirmFiles] = useState([]);
    const [confirmObservation, setConfirmObservation] = useState([]);

    async function handleAddFileToArray() {
      if (newFileUrlToAdd.length > 0) {
        const imageUpload = document.getElementById('image-upload');
        imageUpload.value = '';
        
        setConfirmFiles(confirmFiles => [...confirmFiles, ...newFileUrlToAdd]);

        // Adiciona o mesmo comentários para todas as fotos que foram enviadas
        const observations = new Array(newFileUrlToAdd.length).fill(newFileObservationToAdd);

        setConfirmObservation(confirmObservation => [...confirmObservation, ...observations]);

        setNewFileUrlToAdd([]);
        setNewFileObservationToAdd('');
      }
    }

    async function handleConfirmFiles() {
      if (confirmFiles.length > 0) {
        
        setFilesArray(filesArray => [...filesArray, ...confirmFiles]);

        setObservationsArray(observationsArray => [...observationsArray, ...confirmObservation]);

        setNewFileUrlToAdd([]);
        setNewFileObservationToAdd('');
        setConfirmFiles([]);
        setConfirmObservation([]);
      }
    }
  
    return (
      <Transition.Root show={filesArrayModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setFilesArrayModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 w-full">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Adicionar Arquivos
                    </Dialog.Title>
                      <ul className="mt-4 text-center text-xs  ">
                        {
                          filesArray.length === 0 && confirmFiles.length === 0
                          ? (
                            <li>Nenhum arquivo adicionado</li>
                          ) : (
                            <>
                            { 
                              filesArray.length > 0 
                              && (
                                <>
                                <li><strong>Arquivos Confirmados</strong></li>
                                {
                                  filesArray.map((el, index) => (                          
                                    <li key={ index }>                          
                                      {el.name}                            
                                    </li>
                                  ))
                                }
                                </>
                              )
                            }

                            { 
                              confirmFiles.length > 0 
                              && (
                                <>
                                <li className='mt-[1rem]'><strong>Arquivos Pendentes</strong></li>
                                {
                                  confirmFiles.map((el, index) => (                          
                                    <li key={ index }>                          
                                      {el.name}                            
                                    </li>
                                  ))
                                }
                                </>
                              )
                            }
                            </>
                          )
                        }
                      </ul>
                      <div className="mt-4 text-center">
                        <input id='image-upload' onChange={(e) => {
                            for (let i = 0; i < e.target.files.length; i++) {
                              setNewFileUrlToAdd(files => [...files, e.target.files[i]]);
                            }
                        }} className="w-full mx-auto border border-gray-300 rounded-md text-md" type="file" accept="image/*" multiple />
                      </div>
                      <div className="mt-4">
                        <textarea
                          onChange={(ev) => setNewFileObservationToAdd(ev.target.value)}
                          value={ newFileObservationToAdd }
                          type="text"
                          className="block w-full rounded-md border border-gray-300 text-sm p-2"
                          placeholder="Você pode escrever aqui, uma legenda para o(s) arquivo(s)"
                        />
                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="h-[3rem] inline-flex w-[150px] items-center text-center justify-center rounded-md border-2 border-primary-color text-primary-color px-4 py-2 text-base font-medium shadow-sm hover:bg-primary-color hover:text-white sm:text-sm"
                          onClick={() => {
                            handleAddFileToArray()
                          }}
                        >
                          Adicionar
                        </button>
                        <p className='mt-5 text-gray-500 font-small text-sm my-2'>
                          Clique em Confirmar para que os arquivos sejam salvos
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 text-center flex flex-row items-center justify-between w-full">
                    <button
                      type="button"
                      className="h-[3rem] inline-flex w-[150px] items-center text-center justify-center rounded-md border-2 border-gray-700 text-text-500 px-4 py-2 text-base font-medium shadow-sm opacity-60 hover:opacity-100 sm:text-sm"
                      onClick={() => {
                        setNewFileUrlToAdd([]);
                        setNewFileObservationToAdd('');
                        setConfirmFiles([]);
                        setConfirmObservation([]);
                        setFilesArrayModalOpen(false);
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="h-[3rem] inline-flex w-[150px] items-center text-center justify-center rounded-md border-2 border-primary-color text-primary-color px-4 py-2 text-base font-medium shadow-sm hover:bg-primary-color hover:text-white sm:text-sm"
                      onClick={() => {
                        setFilesArrayModalOpen(false);
                        handleConfirmFiles();
                      }}
                    >
                      Confirmar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
  
          </div>
        </Dialog>
      </Transition.Root>
    )
  }