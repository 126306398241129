import { async } from 'q';
import api from './api';

async function createCalendar(parameters) {
    try {
        const response = await api.post('/calendars', {
            titulo: parameters.titulo,
            inicio: parameters.inicio,
            fim: parameters.fim,
            id_tecnico: parameters.id_tecnico,
            id_autor: parameters.id_autor,
            id_motivo: 1,
            descricao: (parameters.descricao === null || parameters.descricao === "") ? undefined : parameters.descricao
        }).catch(() => { return false }).finally(() => { return false })

        return (response.status === 200) ? response : false;
    } catch {
        return false;
    }
}

async function updateCalendar(parameters, idCalendar) {
    try {
        const response = await api.put(`/calendars/${idCalendar}`, {
            titulo: (parameters.titulo === null || parameters.titulo === "") ? undefined : parameters.titulo,
            inicio: (parameters.inicio === null || parameters.inicio === "") ? undefined : parameters.inicio,
            fim: (parameters.fim === null || parameters.fim === "") ? undefined : parameters.fim,
            descricao: parameters.descricao
        })
            .catch(() => {
                return false;
            })
            .finally(() => {
                return false;
            })

        return (response.status === 200) ? response : false;
    } catch {
        return false;
    }
}

async function deleteCalendar(idCalendar) {
    try {
        const response = await api.delete(`/calendars/${idCalendar}`)
            .catch(() => {
                return false;
            })
            .finally(() => {
                return false;
            })

        return (response.status === 200) ? response : false;
    } catch {
        return false;
    }
}

async function getAllByIdTecnico(idTecnico, initDate, finishDate) {
    try {
        const response = await api.post(`/calendars/search/current-date`, {
            id_tecnico: idTecnico,
            inicio: initDate,
            termino: finishDate
        })
        .catch(() => {
            return [];
        })
        .finally(() => {
            return [];
        })

        if (response.status === 200) {
            return response.data;
        }
    } catch {
        return []
    }
}

export {
    createCalendar,
    getAllByIdTecnico,
    deleteCalendar,
    updateCalendar,
}