import React, { useEffect, useContext } from 'react'
import Header from './../components/layout/Header'
import Footer from './../components/layout/Footer'
import CalendarMenu from './../components/CalendarMenu';
import MainContext from './../context/MainContext';
import ModalContext from './../context/ModalContext';
import AuthContext from '../context/AuthContext';
import api from './../services/api'
import LoadingModal from './../components/modals/LoadingModal';
import ValidateOfflineFlow from './../components/modals/ValidateOfflineFlow';
import NotificationModal from './../components/notification/Notification';
import SyncAnswerNotificationModal from './../components/notification/SyncAnswerNotification';
import ReloadFlowModal from './../components/modals/ReloadFlowModal';
import AnswerNotResponseNotificationModal from './../components/notification/AnswerNotResponse';
import AnswerNotFileNotificationModal from './../components/notification/AnswerNotFile';
import FlowFinishedNotificationModal from './../components/notification/FlowFinished';
import LoginInfo from '../components/layout/LoginInfo';

import { getCurrentWeek } from '../utils/dates';

export default function Calendar() {

  const {
    setCalendarArray,
    calendarOnline,
    setCalendarOnline,
    setWorkOrderArray,
    initDateFilter, setInitDateFilter,
    finishDateFilter, setFinishDateFilter,
  } = useContext(MainContext);

  const { user } = useContext(AuthContext);

  const {
    setLoadingModalOpen,
    setValidateModalOpen, 
    flowFinishedModalOpen, setFlowFinishedModalOpen
  } = useContext(ModalContext);

  useEffect(() => {
    const offlineCalendars = JSON.parse(localStorage.getItem('gestor-manutencao-calendars'));

    if (offlineCalendars) {
      setValidateModalOpen(true)
      return
    }

    setCalendarOnline(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (flowFinishedModalOpen) {
      setTimeout(() => setFlowFinishedModalOpen(false), 5000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowFinishedModalOpen])

  useEffect(() => {
    if (Boolean(calendarOnline)) {
      setLoadingModalOpen(true)

      api.post(`/calendars/search/current-date`, {
        id_tecnico: user.id,
        inicio: initDateFilter,
        termino: finishDateFilter
      }).then(res => {
        setCalendarArray(res.data)
        setLoadingModalOpen(false)
      });

      // api.post(`/order-work/technician`, {
      //   id_tecnico: user.id,
      //   inicio: getCurrentWeek().start.substring(0, 10),
      //   termino: getCurrentWeek().end.substring(0, 10)
      // }).then(res => {
      //   setWorkOrderArray(res.data)
      //   setLoadingModalOpen(false)
      // });

      setCalendarOnline(null)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarOnline])

  return (
    <section className="h-screen h-[calc(var(--vh)*100)] bg-gray-200 w-full text-white">
      <NotificationModal />
      <SyncAnswerNotificationModal />
      <AnswerNotResponseNotificationModal />
      <AnswerNotFileNotificationModal />
      <FlowFinishedNotificationModal />
      <ValidateOfflineFlow />
      <ReloadFlowModal />
      <LoadingModal />
      <Header />
      <LoginInfo />
      <div className='w-full h-[calc(100%-7rem)] -mt-[3rem] flex items-start justify-center relative'>
        <CalendarMenu />
      </div>
      <Footer />
    </section>
  )
}