import { useState } from 'react';
import { BiCode, BiCheck } from "react-icons/bi";
import { Combobox } from '@headlessui/react';
import { useContext } from 'react';
import MainContext from './../../../context/MainContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function OrderWorkComboboxComponent() {
  const { equipamentArray, selectedEquipament, setSelectedEquipament } = useContext(MainContext);
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? equipamentArray
      : equipamentArray.filter((item) => {
          return item.numero_ordem_trabalho.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox as="div" value={selectedEquipament} onChange={setSelectedEquipament}>
      <div className="relative">
        <Combobox.Input
          className="h-[3rem] w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary-color focus:outline-none focus:ring-0 focus:ring-primary-color text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(option) => (option?.numero_ordem_trabalho ? `${option?.numero_ordem_trabalho} [${option?.tipo.toUpperCase()}] - ${option?.nome_fantasia} (${option?.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')})` : option?.numero_ordem_trabalho)}//`${value?.identificacao || ''} - ${value?.nome_fantasia || ''} (${value?.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') || ''})`}
          placeholder="Ordens de Trabalho"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <BiCode className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-0 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.id}
                value={option}
                className={({ active, selected }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9 text-sm text-left hover:text-white hover:bg-primary-color',
                    active ? 'bg-primary-color text-white' : selected ? 'text-primary-color' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-bold')}>{option?.cnpj ? `${option?.numero_ordem_trabalho} [${option?.tipo.toUpperCase()}] - ${option?.nome_fantasia} (${option?.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')})` : `${option?.numero_ordem_trabalho} (${option?.tipo})`}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary-color'
                        )}
                      >
                        <BiCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
