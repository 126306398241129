function formatMoney(value) {
  return new Intl.NumberFormat('pt-br', { style: 'currency', 'currency': 'BRL' }).format(value);
};

function formatTime(value) {
  const date = new Date(value);

  return `${date.toLocaleTimeString().substring(0, 5)}h`;
};

function formatDate(value) {
  const date = new Date(value);

  return date.toLocaleDateString();
}

export {
  formatMoney,
  formatTime,
  formatDate,
};
