import React from 'react';
import { useContext, useEffect } from 'react';
import MainContext from './../../../context/MainContext';

export default function NumberAnswer ({ question, answer }) {
  const { numberValue, setNumberValue, setFilesSavedArray } = useContext(MainContext);

  useEffect(() => {
    handleSet();
  }, [question]);

  function handleSet() {
    if (!answer || !answer.resposta) {
      return;
    }

    answer.anexo.forEach(file => {
      setFilesSavedArray(files => [...files, file]);
    });

    setNumberValue(answer.resposta);
  }

  function handleChange (event) {
    setNumberValue(event.target.value)
  }
  
  return (
    <>
      <input
        type='number'
        value={numberValue}
        onChange={(event) => handleChange(event)}
        key={`${question.ordem}-${question.id}`} 
        name={`${question.ordem}-${question.id}`}
        className='h-[3rem] w-[250px] border-gray-400 rounded-md my-4 text-xl md:text-base'
      />    
    </>
  )
}