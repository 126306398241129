function getCurrentWeek() {
    const startDate = new Date();
    const endDate = new Date();

    const start = startDate.getDate() - startDate.getDay();
    const end = endDate.getDate() + (6 - endDate.getDay());

    const firstDay = new Date(startDate.setDate(start)).toISOString();
    const lastDay = new Date(endDate.setDate(end)).toISOString();

    return {
        start: firstDay, 
        end: lastDay
    };
}

export {
    getCurrentWeek
}
